import { ExampleSet, ExampleSetMethod } from "../example-set";
import { FlowElement, FlowElementType } from "./IFlowElement";

export interface RouterNodeData {
  routeId: string;
}

export interface MessageNodeData {
  label: string;
  labelAR: string;
  text: string;
  textAR: string;
  imageUrl: string;
}

export interface CategoryNodeData {
  categoryId: string;
}

export interface CatalogNodeData {
  label: string;
  labelAR: string;
  text: string;
  textAR: string;
}

export interface AssistantData {
  label: string;
  labelAR: string;
  text: string;
  textAR: string;
  prompt: string;
  promptAR: string;
  apiLink: string;
  apiToken: string;
}

export interface FlowFormData {
  flowId: string;
  screenId: string;
  ctaButtonlabel: string;
  messageBody: string;
  messageFooter: string;
  flowIdAr: string;
  screenIdAr: string;
  ctaButtonlabelAr: string;
  messageBodyAr: string;
  messageFooterAr: string;
  endpoint: string;
  leadgenFormId: string;
  airtableBaseId: string;
  airtableTableName: string;
  airtableToken: string;
}

export enum PaymentMethodTypes {
  MBOKDirect = "mbokDirect",
  Cash = "cash"
}

export const PaymentMethods = {
  mbokDirect: "mBoK Direct Transfer",
  cash: "Cash on Delivery"
};

export interface PaymentNodeData {
  mbokAccount: string;
  mbokName: string;
  mbokNameAR: string;
  contactPhone: string;
  method: PaymentMethodTypes;
}

export enum InputQuestionTypes {
  Name = "name",
  Phone = "phone",
  Email = "email",
  Location = "location",
  Custom = "custom"
}

export enum AnswerTypes {
  Text = "text",
  Image = "image",
  PDF = "PDF"
}

export interface UserInputNodeData {
  questionType: InputQuestionTypes;
  answerType: AnswerTypes;
  questionText: string;
  questionTextAR: string;
  fieldName: string;
}

export interface ChoicesNodeData {
  text: string;
  textAR: string;
  is_main_menu: boolean;
  saveChoice: boolean;
  fieldName: string;
}

export interface PauseNodeData {
  label: string;
  labelAR: string;
  text: boolean;
  textAR: boolean;
}

export interface DeliveryNodeData {
  text: boolean;
  textAR: boolean;
}
export interface AsyncNodeData {
  script: string;
}
export enum MediaNodeTypes {
  Image = "image",
  Video = "video",
  Audio = "audio",
  Sticker = "sticker",
  File = "file"
}

export interface MediaNodeData {
  label: string;
  labelAR: string;
  mediaType: MediaNodeTypes;
  url: string;
  filename: string;
  media?: File;
  attachmentId: {
    messenger: string;
    whatsapp: string;
  };
}

export interface LoadingNodeData {
  type: FlowElementType;
}

export type NodeExampleSet = {
  exampleSet: string;
  exampleSetMethod: ExampleSetMethod;
  exampleSetData: ExampleSet;
};

export type INodesDataTypes = (
  | LoadingNodeData
  | MediaNodeData
  | DeliveryNodeData
  | PauseNodeData
  | ChoicesNodeData
  | UserInputNodeData
  | PaymentNodeData
  | CatalogNodeData
  | CategoryNodeData
  | MessageNodeData
) &
  NodeExampleSet;

export interface INodePosition {
  x: number;
  y: number;
}

export type INodeElement = Omit<FlowElement, "source" | "target"> & {
  data: INodesDataTypes & { exampleSet: string };
  position: INodePosition;
};

export interface RootElement extends FlowElement {
  position: INodePosition;
}
