import { NodeProps, Position } from "react-flow-renderer";
import { AsyncNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { AsyncNodeForm } from "../DialogForms";

const DragElement = <BaseNode title="Async Script" color="#1A202C" isDragElement />;

const NodeComponent = ({ ...rest }: NodeProps<AsyncNodeData>) => (
  <>
    <BaseHandle type="target" position={Position.Top} />
    <BaseNode
      title="Async Script"
      color="#1A202C"
      icon="script.svg"
      form={<AsyncNodeForm />}
      {...rest}
    />
    <BaseHandle type="source" position={Position.Bottom} />
  </>
);

export const AsyncNode: NodeType = {
  type: "async",
  component: NodeComponent,
  dragElement: DragElement
};
