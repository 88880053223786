import { NodeProps, Position } from "react-flow-renderer";
import { RouterNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";

const DragElement = <BaseNode title="Router" color="#1E90FF" isDragElement />;

const NodeComponent = ({ data, ...rest }: NodeProps<RouterNodeData>) => (
  <>
    <BaseNode title={data.routeId} color="#1E90FF" icon="router.svg" {...rest} />
    <BaseHandle type="source" position={Position.Bottom} />
  </>
);

export const RouterNode: NodeType = {
  type: "router",
  component: NodeComponent,
  dragElement: DragElement
};
