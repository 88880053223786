import { NodeType } from "../../types";
import { Assistant } from "./Assistant";
import { CatalogNode } from "./CatalogNode";
import { CategoryNode } from "./CategoryNode";
import { ChoicesNode } from "./ChoicesNode";
import { DeliveryNode } from "./DeliveryNode";
import { FlowForm } from "./FlowForm";
import { LoadingNode } from "./LoadingNode";
import { MediaNode } from "./MediaNode";
import { MessageNode } from "./MessageNode";
import { PauseNode } from "./PauseNode";
import { PaymentNode } from "./PaymentNode";
import { RootNode } from "./RootNode";
import { RouterNode } from "./RouterNode";
import { UserInputNode } from "./UserInputNode";
import { AsyncNode } from "./asyncNode";

export const UserNodes: NodeType[] = [
  MessageNode,
  PauseNode,
  CategoryNode,
  CatalogNode,
  MediaNode,
  DeliveryNode,
  PaymentNode,
  ChoicesNode,
  UserInputNode,
  Assistant,
  FlowForm,
  AsyncNode,
  RouterNode
];

const Nodes: Omit<NodeType, "dragElement">[] = [...UserNodes, RootNode, LoadingNode];

export const NodeTypes = Object.fromEntries(Nodes.map(({ type, component }) => [type, component]));
