/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from "react";
import MonacoEditor, { OnMount } from "@monaco-editor/react";
import { AsyncNodeData } from "@/models/flow";
import { useFormContext } from "react-hook-form";
import { NodeFormWrapper } from "./NodeFormWrapper";

export interface CodeEditorProps {
  name: string;
  label: string;
  defaultValue: string;
  onChange: (value: string) => void;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ name, label, defaultValue, onChange }) => {
  const editorRef = useRef<any>(null);
  const handleEditorDidMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;

    // Custom type definitions that will be available in the editor.
    // These definitions provide type hints and enforce that:
    // 1. The input() function returns an InputData object.
    // 2. The output() function must be called with an OutputData object.
    const extraLib = `
      // Domain types for products and delivery areas:
      interface IProduct {
        name: string;
        desc?: string;
        arabicName?: string;
        arabicDesc?: string;
        url?: string;
        price: number;
        ordering: number;
        hidden: boolean;
        categories: string[];
        ordersCount: number;
        income: number;
        variants: string[];
        thumbnail?: string;
        images: string[];
        createdAt: Date;
        updatedAt: Date;
      }

      interface IDeliveryArea {
        id: string;
        name: string;
      }
      interface ICart {
        products: Array<{ product: IProduct; quantity: number }>;
        deliveryArea: IDeliveryArea | null;
        total: number;
      }

      interface IUserInfo {
        phoneNumber: string;
        name: string;
        email: string | null;
        language: "en" | "ar";
        location: LocationType | null;
        paymentMethod: "cash" | "mbokDirect" | null;
      }

      interface UserInputElement {
        data: {
          questionType: "name" | "phone" | "email" | "location" | "custom";
          answerType: "text" | "image" | "pdf";
          questionText: string;
          questionTextAR: string;
          fieldName?: string;
        };
      }
      type TextAnswer = {
        type: "text";
        text: string;
      };

      type MediaAnswer = {
        type: "image" | "document" | "audio" | "video";
        mediaUrl: string;
      };

      type LocationType = {
        latitude: number;
        longitude: number;
        name?: string;
        address?: string;
      };

      type LocationAnswer = {
        type: "location";
        location: LocationType;
      };

      interface IUserInput {
        question: UserInputElement;
        answer: TextAnswer | MediaAnswer | LocationAnswer | null;
      }

      interface WabaflowElement {
        data: {
          flowId: string;
          screenId: string;
          ctaButtonlabel: string;
          messageBody: string;
          flowIdAr: string;
          screenIdAr?: string;
          ctaButtonlabelAr?: string;
          messageBodyAr?: string;
          messageFooter?: string;
          messageFooterAr?: string;
          endpoint?: string;
        };
      }

      interface IUserFlowForm {
        flow: WabaflowElement;
        response: {
          flow_token: string;
          [key: string]: string;
        } | null;
      }

      // The InputData type that the input() function returns:
      interface InputData {
        user: {
          info: IUserInfo;
          cart: ICart | null;
          flowForms: IUserFlowForm[] | null;
          inputs: IUserInput[] | null;
          lastTextMessage: string | null;
        };
      }

      // The OutputData type that must be provided to the output() function:
      interface OutputData {
        send:
          | {
              type: "text";
              text: string;
            }
          | {
              type: "image";
              url: string;
            }
          | {
              type: "document";
              url: string;
              fileName: string;
            }
          | null;
        navigation: { target: "stop" | "main_menu" | "back" | "next"} | {target: "router", router: string};
      }


      // The functions available to the user code:
      declare function input(): InputData;
      declare function output(result: OutputData): void;
    `;

    // Add the custom types to Monaco’s TypeScript environment.
    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      extraLib,
      "ts:filename/custom-types.d.ts"
    );
  };

  return (
    <div style={{ marginBottom: "1rem", width: "100%" }}>
      <label htmlFor={name} style={{ display: "block", marginBottom: "0.5rem" }}>
        {label}
      </label>
      <MonacoEditor
        height="400px"
        defaultLanguage="typescript"
        defaultValue={defaultValue}
        onChange={(value) => onChange(value ?? "")}
        onMount={handleEditorDidMount}
        options={{
          minimap: { enabled: false },
          automaticLayout: true
        }}
      />
    </div>
  );
};

export const AsyncNodeForm = () => {
  const { setValue, watch } = useFormContext<AsyncNodeData>();
  return (
    <NodeFormWrapper description="This node runs a script.">
      <div style={{ minWidth: "800px" }}>
        <CodeEditor
          name="script"
          label="Script"
          defaultValue={watch("script") || ""}
          onChange={(code) => setValue("script", code)}
        />
      </div>
    </NodeFormWrapper>
  );
};
