export enum FlowElementTypes {
  Edge = "edge",
  Root = "root",
  Message = "message",
  Choices = "choices",
  Catalog = "catalog",
  Category = "category",
  Payment = "payment",
  Delivery = "delivery",
  userInput = "userInput",
  Pause = "pause",
  Media = "media",
  Loading = "loading",
  Assistant = "assistant",
  WAPAFlow = "wapaflow",
  Async = "async",
  Router = "router"
}

export type FlowElementType = `${FlowElementTypes}`;

export interface IFlowElement {
  _id: string;
  type: string;
  source: string;
  target: string;
  data: any;
  position: any;
}

export interface FlowElement {
  id: string;
  type: FlowElementType;
  source: string;
  target: string;
  data: any;
  position: any;
}

export interface IFlowElements {
  elements: Array<IFlowElement>;
}

export const normalizeElement = (element: IFlowElement): FlowElement => ({
  id: element._id,
  type: element.type as FlowElementTypes,
  source: element.source,
  target: element.target,
  data: element.data,
  position: element.position
});
