import { Customer } from "@/models/customers";
import { ProfileItem } from "./ProfileItem";
import { ActiveStatus } from "./ActiveStatus";
import { CollapsableCustomerSection } from "../CollapsableCustomerSection";

type Props = {
  customer: Customer;
};

export const Profile = ({ customer }: Props) => (
  <CollapsableCustomerSection text="Profile">
    <div className="w-full text-gray-500">
      <ProfileItem
        text={customer.name}
        icon="/img/order-icons/customer.svg"
        extend={<ActiveStatus ordersCount={customer.nOfOrders} />}
      />

      <ProfileItem text={customer.email} icon="/img/order-icons/email.svg" />
      <ProfileItem text={customer.id} icon="/img/order-icons/phone.svg" />
      <ProfileItem text={customer.location} icon="/img/icons/office-building.svg" />
    </div>
  </CollapsableCustomerSection>
);
